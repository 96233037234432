export default [
  {
    path: '/:pathMatch(.*)*',
    redirect: '/erro'
  },
  {
    path: '/erro',
    name: 'erro',
    component: () => import('../components/app/erro/Erro.vue'),
    meta: {
      logged: false,
      title: 'Essa página não existe na JokenPlay...',
      layout: 'default-layout'
    }
  },
  {
    path: '/inicio',
    name: 'inicio',
    component: () => import('../components/app/inicio/Inicio.vue'),
    meta: {
      logged: true,
      title: 'JokenPlay',
      layout: 'navbar-layout'
    }
  },
  {
    path: '/jogar',
    name: 'jogar',
    component: () => import('../components/app/jogar/Jogar.vue'),
    meta: {
      logged: true,
      title: 'Jogando JokenPlay',
      layout: 'default-layout'
    }
  },
  {
    path: '/free',
    name: 'free',
    component: () => import('../components/app/free/Free.vue'),
    meta: {
      logged: true,
      title: 'Jogando JokenPlay',
      layout: 'default-layout'
    }
  },
  {
    path: '/moedas',
    name: 'moedas',
    component: () => import('../components/app/moedas/Moedas.vue'),
    meta: {
      logged: true,
      title: 'Suas moedas na JokenPlay',
      layout: 'navbar-layout'
    }
  },
  {
    path: '/saque',
    name: 'saque',
    component: () => import('../components/app/saque/Saque.vue'),
    meta: {
      logged: true,
      title: 'Saque suas moedas JokenPlay',
      layout: 'navbar-layout'
    }
  },
  {
    path: '/procurando',
    name: 'procurando',
    component: () => import('../components/app/procurando/Procurando.vue'),
    meta: {
      logged: true,
      title: 'Procurando oponente...',
      layout: 'default-layout'
    }
  },
  {
    path: '/procurando-free',
    name: 'procurandoFree',
    component: () => import('../components/app/procurandoFree/ProcurandoFree.vue'),
    meta: {
      logged: true,
      title: 'Procurando oponente...',
      layout: 'default-layout'
    }
  },
  {
    path: '/ranking',
    name: 'ranking',
    component: () => import('../components/app/ranking/Ranking.vue'),
    meta: {
      logged: true,
      title: 'Ranking dos jogadores da JokenPlay',
      layout: 'navbar-layout'
    }
  },
  {
    path: '/ranking-xp',
    name: 'rankingXp',
    component: () => import('../components/app/rankingXp/RankingXp.vue'),
    meta: {
      logged: true,
      title: 'Ranking dos jogadores da JokenPlay',
      layout: 'navbar-layout'
    }
  },
  {
    path: '/perfil',
    name: 'perfil',
    component: () => import('../components/app/perfil/Perfil.vue'),
    meta: {
      logged: true,
      title: 'Seu perfil na JokenPlay',
      layout: 'navbar-layout'
    }
  }
]
